import $ from 'jquery'
import httpBuildQuery from 'locutus/php/url/http_build_query'
import parseUrl from 'locutus/php/url/parse_url'
import queryString from 'query-string'

const cacheControl = window.cacheControl || {}

/**
 * Add cache control query param on all links.
 *
 * @param {string} url Url to decorate
 * @returns {string} Return decorated url
 */
function cacheControlForceLink(url) {
  const urlParts = parseUrl(url)
  const queryParts = typeof urlParts.query !== 'undefined' ? queryString.parse(urlParts.query) : {}

  const FORCE_NO_CACHE_VALUE = 1

  if (typeof urlParts.path === 'undefined') {
    urlParts.path = ''
  }

  // Add force no-store cache-control query param
  queryParts[cacheControl.query_name] = FORCE_NO_CACHE_VALUE

  // Create final URL
  const finalUrl = []

  const URL_SCHEME_LENGTH = 2

  if (typeof urlParts.host !== 'undefined') {
    if (typeof urlParts.scheme !== 'undefined') {
      finalUrl.push(urlParts.scheme, '://')
    } else if (url.substring(0, URL_SCHEME_LENGTH) === '//') {
      finalUrl.push('//')
    }

    if (typeof urlParts.user !== 'undefined' || typeof urlParts.pass !== 'undefined') {
      if (typeof urlParts.user !== 'undefined') {
        finalUrl.push(urlParts.user)
      }

      if (typeof urlParts.pass !== 'undefined') {
        finalUrl.push(':', urlParts.pass)
      }

      finalUrl.push('@')
    }

    finalUrl.push(urlParts.host)

    if (typeof urlParts.port !== 'undefined') {
      finalUrl.push(':', urlParts.port)
    }
  }

  // replace multiple array bracket notation by simple []
  const query = httpBuildQuery(queryParts).replace(/%5B%5D%5B\d%5D/g, '%5B%5D')
  finalUrl.push(urlParts.path, '?', query)

  if (typeof urlParts.fragment !== 'undefined') {
    finalUrl.push('#', urlParts.fragment)
  }

  return finalUrl.join('')
}

function cacheControlParseLinks() {
  const domainPattern = new RegExp(`^(${cacheControl.domains.join('|')})$`, 'i')

  $(`a:not(.${cacheControl.parsed_element_class})`).each(function () {
    markElementAsParsed($(this))
    const href = $(this).attr('href')
    const urlParts = parseUrl(href)

    if (typeof urlParts.fragment !== 'undefined' && typeof urlParts.path === 'undefined') {
      return
    }

    // don't rewrite links with no initial href
    if (typeof href === 'undefined') {
      return
    }

    // don't rewrite links with a special behavior
    const specialLinksPattern = /^(#|(tel|mailto|fax|callto|javascript):|DeCryptX)/g
    if (href.match(specialLinksPattern)) {
      return
    }

    if (typeof urlParts.host === 'undefined' || domainPattern.test(urlParts.host)) {
      $(this).attr('href', cacheControlForceLink(href))
    }
  })
}

function cacheControlParseForms() {
  const domainPattern = new RegExp(`^(${cacheControl.domains.join('|')})$`, 'i')

  $(`form:not(.${cacheControl.parsed_element_class})`).each(function () {
    markElementAsParsed($(this))
    const hasAction = typeof $(this).attr('action') !== 'undefined'
    const action = hasAction ? $(this).attr('action') : document.location.href
    const method = typeof $(this).attr('method') !== 'undefined' ? $(this).attr('method').toLowerCase() : 'get'
    const urlParts = parseUrl(action)

    if (method === 'get' && domainPattern.test(urlParts.host)) {
      const input = `<input type="hidden" name="${cacheControl.query_name}" value="1" />`
      $(this).prepend(input)
      /*
      if (hasAction) {
        $(this).attr('action', cacheControlForceLink(action))
      } else if ($(this).find(`input[name="${cacheControl.query_name}"]`).length <= 0) {
        const input = `<input type="hidden" name="${cacheControl.query_name}" value="1" />`
        $(this).prepend(input)
      }
       */
    }
  })
}

function markElementAsParsed(el) {
  el.addClass(`${cacheControl.parsed_element_class}`)
}

function cacheControlInit() {
  cacheControlParseLinks()
  cacheControlParseForms()
}

$(document).on('ready ajaxComplete', () => {
  if (typeof cacheControl !== 'undefined' && parseInt(cacheControl.enabled, 10) === 1) {
    cacheControlInit()
  }
})
